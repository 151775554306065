<div class="toast-container position-fixed top-0 end-0 p-3">
  <div #toast id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <ng-content select="[header]" />
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
        <ng-content select="[body]" />
    </div>
  </div>
</div>