import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { debounceTime, filter, fromEvent, map } from 'rxjs';
import { SearchResult, SearchResultType } from '../../responses/search-response';
import { SearchRequest } from '../../requests/search-request';
import { NgFor, NgIf } from '@angular/common';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { ComponentIconComponent } from "../component-icon/component-icon.component";

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, ComponentIconComponent],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements AfterViewInit {

  @ViewChild('searchInput') protected searchInput: ElementRef | undefined;
  @ViewChild('resultContainer') protected resultContainer: ElementRef | undefined;

  protected projectResults: SearchResult[] | undefined = undefined;
  protected releasePackageResults: SearchResult[] | undefined = undefined;
  protected automationResults: SearchResult[] | undefined = undefined;

  public constructor(private _searchSvc: SearchService, private _router: Router) {
    _router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe({
        next: () => this.resetSearch()
      });
  }

  ngAfterViewInit(): void {
    fromEvent<KeyboardEvent>(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        map((event: KeyboardEvent) => event.target as HTMLInputElement),
        debounceTime(500))
      .subscribe(target => {
        this.onSearch(target);
      });
  }

  private onSearch(target: HTMLInputElement): void {
    console.log('searching for:', target.value);
    var request = { phrase: target.value } as SearchRequest;

    if (target.value.length === 0) this.resultContainer?.nativeElement.classList.remove('show');
    else if (target.value.length >= 3) {
      this.searchProjects(request);
      this.searchAutomations(request);
      this.searchBluePrism(request);
    }
  }

  @HostListener('document:click', ['$event'])
  private onClick(event: MouseEvent): void {
    if (!this.resultContainer?.nativeElement.classList.contains('show')) return;
    if (this.resultContainer?.nativeElement.contains(event.target)) return;

    this.resetSearch();
  }

  private resetSearch(): void {
    this.projectResults = undefined;
    this.resultContainer?.nativeElement.classList.remove('show');

    if (!this.searchInput || !this.searchInput.nativeElement) return;
    this.searchInput.nativeElement.value = '';
    this.projectResults = [];
    this.automationResults = [];
    this.releasePackageResults = [];
  }

  private searchProjects(request: SearchRequest): void {
    this._searchSvc.searchProjects(request).subscribe(response => {
      this.projectResults = [];
      if (!response) return;
      this.projectResults = response.results;
      this.resultContainer?.nativeElement.classList.add('show');
      this.searchInput?.nativeElement.focus();
    });
  }

  private searchAutomations(request: SearchRequest): void {
    this._searchSvc.searchAutomations(request).subscribe(response => {
      this.automationResults = [];
      if (!response) return;
      this.automationResults = response.results;
      this.resultContainer?.nativeElement.classList.add('show');
      this.searchInput?.nativeElement.focus();
    });
  }

  private searchBluePrism(request: SearchRequest): void {
    this._searchSvc.searchBluePrism(request).subscribe(response => {
      this.releasePackageResults = [];
      if (!response) return;
      this.releasePackageResults = response.results.filter(result => result.type === SearchResultType.ReleasePackage);
      this.resultContainer?.nativeElement.classList.add('show');
      this.searchInput?.nativeElement.focus();
    });
  }
}
