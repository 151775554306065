import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { GetProjectGeneralInformationResponse } from '../responses/get-project-general-information-response';

/**
 * Service for interacting with Projects
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private _url: string;

  /**
   * Initializes a new instance of the ProjectService class
   * @param _httpClient The HttpClient to use for requests
   */
  constructor(private _httpClient: HttpClient) {
    this._url = environment.projectUrl;
  }

  /**
   * Get the general information for a Project
   * @param _projectId The Project to get the general information for
   */
  public getProjectGeneralInformation(_projectId: string): Observable<GetProjectGeneralInformationResponse> {
    return this._httpClient.get<GetProjectGeneralInformationResponse>(`${this._url}/project/${_projectId}/general-information`)
      .pipe(catchError((error) => {
        throw error;
      }));
  }
}
