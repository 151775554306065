
<head>
    <!-- ideally use Angular module for fontawesome instead of the below -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="container mt-5">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <div *ngIf="review$ | async as review">
        <div #reviewAccordion class="accordion" id="review-accordian" >
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button #automationNameAccordion (click)="onCollapseEvent()" id=collapsedtest class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <!-- TODO: get release name from releaseId and display instead, show different colors based on whether object or process. -->
                        {{review.releaseId}}
                        <span [class]="'badge rounded-pill ' + getScoreType(getCompositeScore(review))" *ngIf="getCompositeScore(review) !== undefined" >{{getCompositeScore(review)}}%</span>
                        <button (click)="onAddFinding($event)" *ngIf="isCollapsed(automationNameAccordion)" class="btn add-finding-btn p-1" type="button" >
                              <i class="fa fa-plus-circle fa-lg" ></i>
                        </button>
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#review-accordian">
                    <div class="accordion-body">
                    <form [formGroup]="form">
                      <div class="row mb-3">
                          <div class="col-2">
                                <b>Severity</b>
                            </div>
                            <div class="col">
                                <b>Message</b>
                            </div>
                         </div>

                            <form [formGroup]="findingForm" [hidden]="!this.addFindingOn" class="row mb-3">
                              <div class="col-2">
                                  <select class="form-select form-control" formControlName="severity">
                                    <option value="Must Fix">Must Fix</option>
                                    <option value="Recommended">Recommended</option>
                                    <option value="Comment">Comment</option>
                                  </select>
                                </div>
                              <div class="col">
                                  <input type="text" class="form-control" formControlName="message">
                              </div>
                              <div class="col-auto">
                                 <button type="submit" [disabled]="!message.value || !severity.value" class="btn btn-outline-primary btn-sm ps-auto pr-2" (click)="onSubmitFinding(null)">
                                   <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </form>

                          <div class="row mb-3" *ngFor="let comment of this.overallAutomationComments" [formGroup]="comment" >
                              <div class="col-2">
                                    <label>{{ comment.get("severity")?.value }}</label>
                                </div>
                                <div class="col">
                                    <label>{{ comment.get("error")?.value }}</label>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-outline-primary btn-sm comment-btn"><i class="fa fa-comment-o"></i></button>  <!-- TODO: Check developer finding type here, if none of the expected legacy wont fix ignore, then turn to comment box-->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngFor="let processName of processNames; let i = index">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button (click)="onCollapseEvent()" #processAccordionElement class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                            {{processName}}
                            <span [class]="'badge rounded-pill ' + getScoreType(getScore(review, processName))">{{getScore(review, processName)}}%</span>
                            <button (click)="onAddFinding($event)"  *ngIf="isCollapsed(processAccordionElement)" class="btn add-finding-btn p-1"  type="button">
                                <i class="fa fa-plus-circle fa-lg"  ></i>
                            </button>
                        </button>
                    </h2>
                    <div [id]="'collapse' + i" class="accordion-collapse collapse" data-bs-parent="#review-accordian">
                        <div class="accordion-body">
                            <form [formGroup]="form">
                                <div class="row mb-3">
                                    <div class="col-2">
                                        <b>Page</b>
                                    </div>
                                    <div class="col-2">
                                        <b>Stage</b>
                                    </div>
                                    <div class="col-2">
                                        <b>Severity</b>
                                    </div>
                                    <div class="col-5">
                                        <b>Message</b>
                                    </div>
                                </div>
                                <form [formGroup]="findingForm" [hidden]="!this.addFindingOn" class="row mb-3">
                                     <div class="col-2">
                                         <input type="text" class="form-control" formControlName="page">
                                     </div>
                                     <div class="col-2">
                                         <input type="text" class="form-control" formControlName="stage">
                                     </div>
                                     <div class="col-2">
                                         <select class="form-select form-control" formControlName="severity">
                                            <option value="Must Fix">Must Fix</option>
                                            <option value="Recommended">Recommended</option>
                                            <option value="Comment">Comment</option>
                                          </select>
                                     </div>
                                       <div class="col">
                                         <input type="text" class="form-control" formControlName="message">
                                     </div>
                                     <div class="col-auto">
                                       <button type="submit" [disabled]="findingForm.invalid" class="btn btn-outline-primary btn-sm ps-auto pr-2" (click)="onSubmitFinding(processName)">
                                        <i class="fa fa-plus"></i>
                                      </button>  <!-- TODO: Check developer finding type here, if none of the expected legacy wont fix ignore, then turn to comment box. Turn to red-->
                                   </div>
                                </form>
                                <div class="row mb-3" *ngFor="let finding of getGroupsByProcess(processName)" [formGroup]="finding">
                                    <div class="col-2">
                                        <label>{{ finding.get("bpSubsheetName")?.value }}</label>
                                    </div>
                                    <div class="col-2">
                                        <label>{{ finding.get("bpStageName")?.value }}</label>
                                    </div>
                                    <div class="col-2">
                                        <label>{{finding.get('severity')?.value}}</label>
                                    </div>
                                    <div class="col-5">
                                        <label>{{ finding.get("error")?.value }}</label>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-outline-primary btn-sm comment-btn"><i class="fa fa-comment-o"></i></button>  <!-- TODO: Check developer finding type here, if none of the expected legacy wont fix ignore, then turn to comment box-->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <ngx-spinner [name]="spinnerName" bdColor="rgba(0, 0, 0, 0.25)" size="medium" color="#fff" type="ball-circus" [fullScreen]="false">
        <p style="color: white">Loading...</p>
    </ngx-spinner>
</div>


<bs-toast #successToast>
    <ng-container header>
        <strong class="me-auto text-success">Success</strong>
    </ng-container>
    <ng-container body>
         {{this.successToastMessage}}
    </ng-container>
</bs-toast>

<bs-toast #errorToast>
    <ng-container header>
        <strong class="me-auto text-danger">Fail</strong>
    </ng-container>
    <ng-container body>
        {{this.errorToastMessage}}
    </ng-container>
</bs-toast>

