import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

/**
 * Service for logging insights to Application Insights
 * @see https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 */
@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  private _insights!: ApplicationInsights;

  /**
   * Initializes a new instance of the InsightsService class
   */
  constructor() {
    if (environment.logProvider !== 'insights') return;
    this._insights = new ApplicationInsights({ config: environment.appInsights });
    this._insights.loadAppInsights();
  }

  /**
   * Set the authenticated user context for Application Insights
   * @param userId The user ID to set
   */
  public setUserId(userId: string): void {
    this._insights.setAuthenticatedUserContext(userId);
  }

  /**
   * Clear the authenticated user context for Application Insights
   */
  public clearUserId(): void {
    this._insights.clearAuthenticatedUserContext();
  }

  /**
   * Log a page view
   * @param name The name of the page
   * @param uri The URI of the page
   */
  public logPageView(name?: string, uri?: string): void {
    this._insights.trackPageView({ name, uri });
  }

  /**
   * Log a custom event
   * @param name The name of the event
   * @param properties The properties to log
   */
  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this._insights.trackEvent({ name }, properties);
  }

  /**
   * Log an exception
   * @param exception The exception to log
   */
  public logException(exception: Error, severityLevel?: SeverityLevel): void {
    this._insights.trackException({ exception, severityLevel });
  }

  /**
   * Log a trace
   * @param message The message to log
   * @param properties The properties to log
   */
  public logTrace(message: string, properties?: { [key: string]: any }): void {
    this._insights.trackTrace({ message }, properties);
  }

  /**
   * Log a metric
   * @param name The name of the metric
   * @param average The average value of the metric
   * @param sampleCount The sample count of the metric
   * @param min The minimum value of the metric
   * @param max The maximum value of the metric
   */
  public logMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number): void {
    this._insights.trackMetric({ name, average, sampleCount, min, max });
  }
}
