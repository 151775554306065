import { Routes } from '@angular/router';
import { RootLayout } from './layouts/root/root.layout';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { AutomationFactSheetPage } from './pages/automation-fact-sheet/automation-fact-sheet.page';
import { AutomationCodeReviewPage } from './pages/automation-code-review/automation-code-review.page';
import { ProjectFactSheetPage } from './pages/project-fact-sheet/project-fact-sheet.page';
import { AutomationReviewDetailsPage } from './pages/automation-review-details/automation-review-details.page';
 
export const routes: Routes = [
    {
        path: '',
        component: RootLayout,
        children: [
            { path: '', component: DashboardPage },
            {
                path: 'automation/:id', children: [
                    { path: 'fact-sheet', component: AutomationFactSheetPage },
                    { path: 'code-review', component: AutomationCodeReviewPage, children: [
                        { path: ':reviewId', component: AutomationReviewDetailsPage}
                    ]
                    },
                ]
            }, {
                path: 'project/:id', children: [
                    { path: 'fact-sheet', component: ProjectFactSheetPage },
                ]
            }
        ]
    }
];