import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  private _maxTextWidth: number = 200;

  constructor() { }

  public createSvg(width: string = '100%', height: string = '200px'): SVGSVGElement {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', width);
    svg.setAttribute('height', height);
    return svg;
  }

  public createGroup(x: string = '10', y: string = '10'): SVGElement {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('transform', `translate(${x}, ${y})`);
    return group;
  }

  public createRect(style:string): SVGRectElement {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.classList.add(style);
    return rect;
  }

  public createText(text: string, x: number = 0, y: number = 0, style: string = ''): SVGTextElement {
    const el = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    if (style !== '') el.classList.add(style);
    el.setAttribute('x', `${x}`);
    el.setAttribute('y', `${y}`);

    var length = this.getTextSize(text).width;
    if (length > this._maxTextWidth) { text = this.truncate(text); }

    el.textContent = text;
    return el;
  }

  public getTextSize(text: string): { width: number, height: number } {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return { width: 0, height: 0 };
    }

    var style = window.getComputedStyle(document.body);
    var size = style.getPropertyValue('font-size');
    var font = style.getPropertyValue('font-family');

    context.font = `${size} ${font}`;

    const metrics = context.measureText(text);

    return { width: metrics.width, height: parseInt(size) };
  }

  /**
   * Truncate text that is wider than the maximum width
   * @param text The text to truncate
   * @returns The truncated text
   */
  public truncate(text: string): string {
    // get width in text in pixels
    var width = this.getTextSize(text).width;

    // get the number of characters that can fit in the max width
    var numChars = Math.floor(this._maxTextWidth / (width / text.length));

    // remove characters from the middle of the text and replace with '...'
    return text.substring(0, (numChars / 2) - 3) + '...' + text.substring(text.length - (numChars / 2) + 2);
  }
}
