<div class="row fact-sheet-section">
    <div class="col-2">General Information</div>
    <div class="col">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col-2">Name</div>
                    <div class="col">
                        <ng-content select="[name]"></ng-content>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">Description</div>
                    <div class="col">
                        <ng-content select="[description]"></ng-content>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">ID</div>
                    <div class="col">
                        <ng-content select="[id]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
