<header class="d-flex justify-content-center py-3 border-bottom bg-primary">
    <a href="/" class="d-flex align-items-center mb-0 ms-3">
      <span class="fs-2 fw-bold">IADO</span>
    </a>
    <div class="search ms-auto me-3">
        <app-search />
    </div>
    <div class="profile me-3">
        <img src="" alt="">
    </div>
  </header>

  <router-outlet></router-outlet>