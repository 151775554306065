import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateAutomationCommand } from '../requests/create-automation-command';
import { GetAutomationGeneralInformationResponse } from '../responses/get-automation-general-information-response';
import { GetAutomationGeneralInformationQuery } from '../requests/get-automation-general-information-query';
import { AddComponentsCommand } from '../requests/add-components-command';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GetAutomationComponentsResponse } from '../responses/get-automation-components-response';
import { GetAutomationComponentsQuery } from '../requests/get-automation-components-query';

/**
 * Service for interacting with Automations
 */
@Injectable({
  providedIn: 'root'
})
export class AutomationService {


  private _url: string;

  constructor(private _httpClient: HttpClient) {
    this._url = environment.devOpsUrl;
  }

  /**
   * Create a new Automation
   * @param automation The Automation to create
   */
  public createAutomation(automation: CreateAutomationCommand): Observable<any> {
    return this._httpClient.post(`${this._url}/automation`, automation);
  }

  /**
   * Get the general information for an Automation
   * @param request The request to get the general information
   */
  public getGeneralInformation(request: GetAutomationGeneralInformationQuery): Observable<GetAutomationGeneralInformationResponse> {
    return this._httpClient.get<GetAutomationGeneralInformationResponse>(`${this._url}/automation/${request.automationId}/general-information`);
  }

    /**
   * Get the package for an Automation
   * @param request The request to get the package
   */
  public getPackage(request: GetAutomationGeneralInformationQuery): Observable<GetAutomationGeneralInformationResponse> {
    return this._httpClient.get<GetAutomationGeneralInformationResponse>(`${this._url}/automation/${request.automationId}/package`);
  }

  /**
   * Get all components of an Automation
   * @param request The request to get the components of an Automation
   * @returns The components of the Automation
   */
  public getComponents(request: GetAutomationComponentsQuery): Observable<GetAutomationComponentsResponse> {
    return this._httpClient.get<GetAutomationComponentsResponse>(`${this._url}/automation/${request.id}/components`);
  }

  /**
   * Add components to an Automation
   * @param request The request to add components to an Automation
   */
  public addComponents(request: AddComponentsCommand): Observable<any> {
    return this._httpClient.post(`${this._url}/automation/components`, request)
      .pipe(catchError((error) => {
        throw error;
      }));
  }
}


