import { AfterViewInit, Component, ElementRef, Host, HostListener, Input, numberAttribute, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RelationshipGraphService } from './relationship-graph.service';
import { BehaviorSubject, catchError, Observable, Observer, of, Subject, switchMap, throwError } from 'rxjs';
import { RelationshipService } from '../../services/relationship.service';
import { GetProcessRelationshipResponse } from '../../responses/get-process-relationship-response';
import { RelationshipGraph } from './relationship-graph';
import { RelationshipType } from './relationship-type';
import { NgxSpinnerModule } from 'ngx-spinner';
import { isNgContainer } from '@angular/compiler';

@Component({
  selector: 'app-relationship-view',
  standalone: true,
  imports: [NgxSpinnerModule],
  templateUrl: './relationship-view.component.html',
  styleUrl: './relationship-view.component.scss'
})
export class RelationshipViewComponent implements AfterViewInit {

  @Input('graph') graph$!: BehaviorSubject<RelationshipGraph.Node | null>;

  @ViewChild('relationshipContainer', { static: true }) relationshipContainer!: ElementRef;

  public spinnerName: string = 'relationshipSpinner';

  private _svg!: SVGElement;
  private _itemContainer: SVGElement | undefined = undefined;
  private _node!: RelationshipGraph.Node;

  constructor(
    private _relationshipSvc: RelationshipService,
    private _route: ActivatedRoute,
    private _graphSvc: RelationshipGraphService
  ) { }

  ngAfterViewInit(): void {
    this.graph$?.subscribe((node) => {
      console.log(node);
      if (!node) return;
      this._node = node;
      this.createSvg(this._node);
    });
  }

  /**
   * Creates the SVG element including the root node and groups for each distinct type.
   * @param node The root node of the graph.
   */
  private createSvg(node: RelationshipGraph.Node,): void {
    this._svg?.remove();
    if (!node || !node.children) return;

    const depth = this.determineDepth(node);
    const distinctTypes = this.getDestinctTypes(node);
    console.log(distinctTypes);

    this._svg = this._graphSvc.createSvg('100%', '200px'); // Creates the SVG element.
    this.relationshipContainer.nativeElement.appendChild(this._svg);

    var root = this._graphSvc.addItem(this._svg, `${node.name}`, node.type, 10, 10); // Adds the root node to the SVG.

    var width = Number.parseFloat(root.getAttribute('width') as string); // Parses the width of the root node.
    var xOffset = Math.round(width) + 15; // Rounds the width of the root node.

    // create groups for each distinct type
    var yOffset = 10;
    distinctTypes.forEach((item: RelationshipType) => {
      const childCount = node.children?.filter((child) => child.type === item).length; // Gets the count of children with the current type.
      var group = this._graphSvc.addGroup(this._svg, `${childCount} ${this.toLabel(item)}`, item, 275, yOffset);
      group.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'a')[0].addEventListener('click', (event) => this.addGroupItems(this, group, item, event));
      this._graphSvc.addPath(this._svg, { x: xOffset, y: 20 }, { x: 275, y: yOffset + 10 });
      yOffset += 30;
    });

  }

  /**
   * Adds the items for the selected group to the SVG.
   * @param context The context of the relationship view component.
   * @param type The type of the group.
   */
  private addGroupItems(context: RelationshipViewComponent, group: SVGElement, type: RelationshipType, event: MouseEvent): void {
    this.removeGroupItems(context);
    var items: RelationshipGraph.Node[] = context._node.children?.filter((child) => child.type === type) || [];

    var target = event.target as SVGElement;
    if (target.nodeName === 'text') target = target.parentNode?.parentNode as SVGElement; // if event.target is of type text, get group element

    var yStart = (target.getBoundingClientRect().top - context._svg.getBoundingClientRect().top) + 10;
    var yOffset: number = 10;

    context._itemContainer = this._graphSvc.addContainer(context._svg, 475, 0, 255, 200);
    items.forEach((node) => {
      if (context._itemContainer === undefined) return;
      this._graphSvc.addItem(context._itemContainer, node.name, type, 40, yOffset);
      this._graphSvc.addPath(context._itemContainer, { x: 0, y: yStart }, { x: 40, y: yOffset + 10 });
      yOffset += 30;
    });
  }

  /**
   * Removes the rendered child items from the SVG.
   * @param context The context of the relationship view component.
   */
  private removeGroupItems(context: RelationshipViewComponent): void {
    this._itemContainer?.remove();
    this._itemContainer = undefined;
  }

  /**
   * Determines the depth of the graph.
   * @param node Node to determine the depth of.
   * @returns The depth of the graph.
   */
  private determineDepth(node: RelationshipGraph.Node): number {
    if (node.children) {
      return node.children.map((child) => this.determineDepth(child)).reduce((a, b) => Math.max(a, b), 0) + 1;
    }
    return 0;
  }

  /**
   * Gets the distinct types of all children of the current node.
   * @param node Node to get the distinct types of.
   * @returns The distinct types in the graph.
   */
  private getDestinctTypes(node: RelationshipGraph.Node): RelationshipType[] {
    if (node.children && node.children.length > 0) {
      return node.children
        .map((child) => this.getDestinctTypes(child)) // Continues with the children of the current node.
        .reduce((previous, current) => current.some((x) => previous.includes(x)) ? previous : previous.concat(current), []); // Reduces the array of types to a single array.
    }

    return [node.type]; // Returns the type of the current node.
  }

  /**
   * Converts the string type to the enum type.
   * @param type The string type to convert.
   * @returns The enum type.
   */
  private toLabel(type: RelationshipType): string {
    switch (type) {
      case RelationshipType.Automation:
        return 'Automation';
      case RelationshipType.Credential:
        return 'Credential';
      case RelationshipType.EnvironmentVariable:
        return 'Environment Variable';
      case RelationshipType.Object:
        return 'Object';
      case RelationshipType.Process:
        return 'Process';
      case RelationshipType.Project:
        return 'Project';
      case RelationshipType.Queue:
        return 'Queue';
      case RelationshipType.ReleasePackage:
        return 'Release Package';
      default:
        return 'None';
    }
  }

  @HostListener('document:wheel', ['$event'])
  private onSvgScroll(event: Event): void {
    if (event.target !== this._svg && (event.target as SVGElement)?.ownerSVGElement !== this._svg) return; // Checks if the event target is the SVG element.
    if (!this._itemContainer) return; // Checks if the item container exists.
    if (this._svg.getBoundingClientRect().height > this._itemContainer.getBoundingClientRect().height) return; // Checks if the SVG height is greater than the item container height.

    var deltaY = (event as WheelEvent).deltaY * -1; // Calculates the delta Y value and slows it down.
    var value = this._itemContainer.getAttribute('transform') as string; // Gets the transform attribute of the item container.
    value = value?.substring(10, value.length - 1) as string; // Removes the 'translate(' and ')' from the value.
    let translate = value.split(','); // Splits the value into an array.

    if (!translate || translate.length < 2) return; // Checks if the array exists and has at least two elements.
    let y = Number.parseFloat(translate[1]) + deltaY; // Parses the Y value and adds the delta Y value.

    let isScrolling = false; // Initializes the scrolling flag.
    if (y >= 0) {
      y = 0; // Checks if the Y value is greater than 0, and sets it to 0 if it is.
      isScrolling = false; // Sets the scrolling flag to false.
    }
    else if (y < this._svg.getBoundingClientRect().height - this._itemContainer.getBoundingClientRect().height - 15) {
      y = this._svg.getBoundingClientRect().height - this._itemContainer.getBoundingClientRect().height - 15; // Checks if the Y value is greater than the SVG height minus the item container height, and sets it to the difference if it is.
      isScrolling = false; // Sets the scrolling flag to false.
    } else {
      isScrolling = true; // Sets the scrolling flag to true.
    }

    if (isScrolling) this._itemContainer.setAttribute('transform', `translate(${translate[0]}, ${y.toFixed(5)})`); // Sets the transform attribute of the item container.

    for (let i = this._itemContainer.childNodes.length - 1; i >= 0; i--) {
      if (!isScrolling) return;
      let child = this._itemContainer.childNodes[i] as SVGElement; // Gets the child element.
      if (child.nodeName !== 'path') continue; // Checks if the child is a path.
      let d = child.getAttribute('d') as string; // Gets the d attribute of the child.
      let pathValues = d.split(' '); // Splits the d attribute into an array.
      let fromValues = pathValues[0].replace(/[A-Za-z]/g, '').split(','); // Gets the from values.
      let from = { x: Number.parseFloat(fromValues[0]), y: Number.parseFloat(fromValues[1]) - deltaY }; // Parses the from values and adds the delta Y value.
      let toValues = pathValues[3].replace(/[A-Za-z]/g, '').split(','); // Gets the to values.
      let to = { x: Number.parseFloat(toValues[0]), y: Number.parseFloat(toValues[1]) }; // Parses the to values.
      if (Number.parseFloat(fromValues[1]) === Number.parseFloat(fromValues[1]) - deltaY) continue; // Checks if the from Y value is equal to the from Y value minus the delta Y value.
      this._graphSvc.addPath(this._itemContainer as SVGElement, from, to); // Adds the path to the item container.
      child.remove(); // Removes the child element.
    }
  }
}
