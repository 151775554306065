export enum RelationshipType {
    None,
    Automation = 'A',
    Credential = 'C',
    EnvironmentVariable = 'E',
    Object = 'O',
    Process = 'P',
    Project = 'PR',
    Queue = 'Q',
    ReleasePackage = 'R',
}
