<div class="page-grid">
    <header>
        <app-page-header [hasShadow]="false">
            <div class="d-flex gap-3">
                <app-component-icon type="Automation" size="l" />
                <span>{{(generalInfo$ | async)?.name}}</span>
            </div>
        </app-page-header>
    </header>
    <nav>
        <app-automation-navigation></app-automation-navigation>
    </nav>
    <main>
        <div class="container-fluid">
            <app-general-information>
                <div name>
                    {{(generalInfo$ | async)?.name}}
                </div>
                <div description>
                    {{(generalInfo$ | async)?.description}}
                </div>
                <div id>
                    {{(generalInfo$ | async)?.automationId}}
                </div>
            </app-general-information>
            <app-relationship-view [graph]="graph$" />
        </div>
    </main>
    <nav class="page-actions">
        <ul class="navbar-nav">
            <li class="nav-title">Actions</li>
            <li class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#releaseModal">Add Components</a>
                <a #addItemHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                    <fa-icon [icon]="faCircleQuestion"></fa-icon>
                </a>
            </li>
        </ul>
    </nav>
</div>

<!-- #region Release Package Modal -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div #releaseModal class="modal fade" id="releaseModal" tabindex="-1" aria-labelledby="releaseModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div #releaseModalContent>
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="releaseModalLabel">
                            Find Release Package
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-light" role="alert" [innerHTML]="addItemHelpText"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <!-- #region Search -->
                                <div class="mb-3">
                                    <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                        <label for="searchInput" class="form-label">Search</label>
                                        <a #searchHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                            <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                        </a>
                                    </div>
                                    <input type="text" class="form-control" id="searchInput" placeholder="Search..." formControlName="search" />
                                    <div *ngIf="noContentResult" class="form-text text-danger">
                                        The release package could not be found.
                                    </div>
                                </div>
                                <!-- #endregion -->
                                <!-- #region Releases -->
                                <div class="mb-3">
                                    <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                        <label for="searchResults" class="form-label">
                                            Releases</label>
                                        <a #releaseHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                            <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                        </a>
                                    </div>
                                    <div class="position-relative">
                                        <select class="form-select" id="searchResults" size="4" formControlName="releases">
                                            <option *ngFor="let item of searchResults" [value]="item.key">
                                                {{item.value}}
                                            </option>
                                        </select>
                                        <ngx-spinner [fullScreen]="false" [name]="searchResultSpinner" type="ball-8bits" size="default"></ngx-spinner>
                                    </div>
                                </div>
                                <!-- #endregion -->
                                <!-- #region Components -->
                                <div class="mb-3">
                                    <div class="d-flex flex-row flex-grow-1 justify-content-between">
                                        <label for="searchResults" class="form-label">
                                            Release Components</label>
                                        <a #componentHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                                            <fa-icon [icon]="faCircleQuestion"></fa-icon>
                                        </a>
                                    </div>
                                    <div class="position-relative">
                                        <select class="form-select" multiple id="searchResults" size="4" formControlName="components">
                                            <option *ngFor="let item of releaseEntries" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <ngx-spinner [fullScreen]="false" [name]="releasehResultSpinner" type="ball-8bits" size="default"></ngx-spinner>
                                    </div>
                                </div>
                                <!-- #endregion -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button #submitButton type="submit" class="btn btn-primary" [disabled]="form.invalid">
                            Add Components
                        </button>
                        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- #endregion-->

<!-- #region Toast -->
<bs-toast #toast>
    <ng-container header>
        <strong class="me-auto text-success">Success</strong>
    </ng-container>
    <ng-container body>
        {{addedComponentsCount}} components successfully added to the <span class="text-automation fw-bold">Automation Fact Sheet</span>.
    </ng-container>
</bs-toast>
<!-- #endregion -->