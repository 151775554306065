<div class="d-flex flex-column align-items-end position-relative">
    <input #searchInput type="text" class="form-control" placeholder="Search...">
    <div #resultContainer class="result">
        <!-- #region Project results -->
        <ul *ngIf="projectResults && projectResults.length > 0" class="p-0">
            <span class="title text-project ps-2 p-1 pb-0">Projects</span>
            <a *ngFor="let item of projectResults" [routerLink]="['/', 'project', item['key'],'fact-sheet']">
                <li class="d-flex gap-3 ps-2 p-1 pb-0"><app-component-icon type="Project" />
                    <span>{{item['value']}}</span>
                </li>
            </a>
        </ul>
        <!-- #endregion -->
        <!-- #region Automation results -->
        <ul *ngIf="automationResults && automationResults.length > 0" class="p-0">
            <span class="title text-automation ps-2 p-1 pb-0">Automations</span>
            <a *ngFor="let item of automationResults" [routerLink]="['/', 'automation', item['key'],'fact-sheet']">
                <li class="d-flex gap-3 ps-2 p-1 pb-0"><app-component-icon type="Automation" />
                    <span>{{item['value']}}</span>
                </li>
            </a>
        </ul>
        <!-- #endregion -->
        <!-- #regio Release Package results -->
        <ul *ngIf="releasePackageResults && releasePackageResults.length > 0" class="p-0">
            <span class="title text-release ps-2 p-1 pb-0">Release Packages</span>
            <a *ngFor="let item of releasePackageResults" [routerLink]="['/', 'release-package', item['key'],'fact-sheet']">
                <li class="d-flex gap-3 ps-2 p-1 pb-0"><app-component-icon type="Release" />
                    <span>{{item['value']}}</span>
                </li>
            </a>
        </ul>
        <!-- #endregion -->
    </div>
</div>