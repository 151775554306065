import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})

/**
 * Used to share data betweeen child and parent components that use a router outlet
 */
export class ChildParentSharedService {

  /**
   * For grabbing a review id from the details page
   */
  reviewIdSubject$ = new Subject<any>();
}