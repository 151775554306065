<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div #requestModal class="modal fade" id="requestCodeReviewModal" tabindex="-1" role="dialog" aria-labelledby="requestModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="requestModalLabel">Request Code Review</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <select class="form-select" formControlName="change" placeholder="Change">
                <option value="null" disabled [selected]="true"> Change </option>
                <option *ngFor="let changeType of changeTypeMapping | keyvalue; let j = 'index+1'" [value]="changeTypes[j]">
                    {{ changeType.value }} 
            </option> 
          </select> 
        </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"  [disabled]="form.invalid" >Request</button>
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
          </div>
      </div>
    </div>
  </div>
</form>
