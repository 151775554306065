import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { InsightsService } from './insights.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

/**
 * Service for handling errors
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ErrorHandler {

  private _insights = this._injector.get(InsightsService);

  /**
   * Initializes a new instance of the ErrorService class
   */
  constructor(private _injector: Injector) {
    super();
  }

  /**
   * Handle an error
   * @param error The error to handle
   */
  public override handleError(error: Error | HttpErrorResponse): void {
    if (environment.logProvider === 'insights') this._insights.logException(error);
    else super.handleError(error);
  }
}
