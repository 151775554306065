<div class="page-grid">
    <header>
        <app-page-header [hasShadow]="false">Dashboard</app-page-header>
    </header>
    <nav>
    </nav>
    <main>
        <!-- <app-relationship-view></app-relationship-view> -->
        <!-- <app-relationship-view></app-relationship-view> -->
    </main>
    <nav class="page-actions">
        <ul class="navbar-nav">
            <li class="nav-title">Actions</li>
            <li class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#createAutomationModal">Create Automation</a>
                <a #createAutomationHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
                    <fa-icon [icon]="faCircleQuestion"></fa-icon>
                </a>
            </li>
        </ul>
    </nav>
</div>

<!-- #region Automation Modal -->
<app-add-automation (onSearch)="onSearch($event)" (onSubmit)="onSubmit($event)" [searchResult]="searchResults$" />
<!-- #endregion-->