/**
 * Response model for search request
 * This is used only interally by the search component and is not 
 * to be exposed to the application  * use the SearchResult model instead
 */
export interface SearchResponse {
    /**
     * The results matching the search phrase
     */
    results: SearchResult[];

    /**
     * The total number of processes
     * @description Do not use this field, it will be removed in the future
     */
    totalProcesses: number;

    /**
     * The list of objects that match the search criteria
     * @description Do not use this field, it will be removed in the future
     */
    objects: Record<string, string>[];

    /**
     * The total number of objects
     * @description Do not use this field, it will be removed in the future
     */
    totalObjects: number;

    /**
     * The list of groups that match the search criteria
     * @description Do not use this field, it will be removed in the future
     */
    groups: Record<string, string>[];

    /**
     * The total number of groups
     * @description Do not use this field, it will be removed in the future
     */
    totalGroups: number;
}

/**
 * Model for search result
 */
export interface SearchResult {
    /**
     * The key of the search result
     */
    key: string;

    /**
     * The value of the search result
     */
    value: string;

    type: SearchResultType;
}

/**
 * Enum for search result type
 */
export enum SearchResultType {
    None = 0,
    Release = 1,
    Process = 2,
    Object = 3,
    Credential = 4,
    WorkQueue = 5,
    EnvironmentVariable = 6,
    ReleasePackage = 7
}