import { Component, OnInit, ViewChild } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ComponentIconComponent } from '../../components/component-icon/component-icon.component';
import { GetProjectGeneralInformationResponse } from '../../responses/get-project-general-information-response';
import { Observer, Subject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { GeneralInformationComponent } from "../../components/general-information/general-information.component";
import { ProjectService } from '../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { TooltipComponent } from "../../bootstrap/tooltip/tooltip.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { AddAutomationComponent } from "../../components/add-automation/add-automation.component";
import { CreateAutomationCommand } from '../../requests/create-automation-command';
import { AutomationService } from '../../services/automation.service';

@Component({
  selector: 'app-project-fact-sheet',
  standalone: true,
  imports: [
    AsyncPipe,
    PageHeaderComponent,
    ComponentIconComponent,
    GeneralInformationComponent,
    TooltipComponent,
    FontAwesomeModule,
    AddAutomationComponent
  ],
  templateUrl: './project-fact-sheet.page.html',
  styleUrl: './project-fact-sheet.page.scss'
})
export class ProjectFactSheetPage implements OnInit {

  @ViewChild(AddAutomationComponent) addAutomationComponent!: AddAutomationComponent

  public generalInfo$: Subject<GetProjectGeneralInformationResponse> = new Subject<GetProjectGeneralInformationResponse>();
  public faCircleQuestion = faCircleQuestion;

  public addAutomationHelp = 'This is a tooltip';

  public projectId: string;

  constructor(
    private _route: ActivatedRoute,
    private _projectSvd: ProjectService,
    private _automationSvc: AutomationService
  ) {
    this.projectId = this._route.snapshot.paramMap.get('id') as string;
  }

  public ngOnInit(): void {
    this._projectSvd.getProjectGeneralInformation(this.projectId)
      .subscribe(this.getGeneralInformationObserver());
  }

  /**
   * Submitted the command to the automation service
   * @param request The command to submit
   */
  public onSubmit(request: CreateAutomationCommand) {
    if (request.projectId === null || request.projectId === '' || request.name === null || request.name === '') return;

    this._automationSvc.createAutomation(request).subscribe({
      next: response => {
        this.addAutomationComponent.hide();
      }
    });
  }

  /**
   * Handles the response from the get general information request
   */
  private getGeneralInformationObserver(): Partial<Observer<GetProjectGeneralInformationResponse>> {
    return {
      next: (generalInfo) => {
        this.generalInfo$.next(generalInfo);
      }
    };
  }

}
