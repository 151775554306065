import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-automation-navigation',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './automation-navigation.component.html',
  styleUrl: './automation-navigation.component.scss'
})
export class AutomationNavigationComponent implements OnInit {
  public id: string = '';

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._route.paramMap.subscribe({
      next: (paramMap) => {
        this.id = paramMap.get('id') as string;
      }
    })
  }
}
