import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-component-icon',
  standalone: true,
  imports: [],
  templateUrl: './component-icon.component.html',
  styleUrl: './component-icon.component.scss'
})
export class ComponentIconComponent {
  @Input() type: 'Automation' | 'Credential' | 'EnvVar' | 'Process' | 'Release' | 'Object' | 'Project' = 'Automation';
  @Input() size: 's' | 'm' | 'l' = 'm';
}
