import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { InsightsService } from './services/insights.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'tjx-iado-web';

  constructor(private _insights: InsightsService) { }
}
