import { Component } from '@angular/core';

@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [],
  templateUrl: './general-information.component.html',
  styleUrl: './general-information.component.scss'
})
export class GeneralInformationComponent {
}
