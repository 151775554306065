<div class="page-grid">
    <header>
        <app-page-header [hasShadow]="false">
            <div class="d-flex gap-3">
                <app-component-icon type="Automation" size="l" />
                <span>{{(generalInfo$ | async)?.name}}</span>
            </div>
        </app-page-header>
    </header>
    <nav>
        <app-automation-navigation></app-automation-navigation>
    </nav>

    <main>
        <router-outlet (deactivate)="onChildComponentDeactivate($event)" #reviewDetailsOutlet="outlet"></router-outlet>
    </main>
    <nav class="page-actions">
        <ul class="navbar-nav">
            <li class="nav-title">Actions</li>
            <li *ngIf="!reviewDetailsOutlet.isActivated"  class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#requestCodeReviewModal">Request Code Review</a>
            </li>
            <li *ngIf="reviewDetailsOutlet.isActivated" class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#requestApprovalModal">Request Approval</a>  <!--DEBT: Add guard here to only be shown to reviewers -->
            </li>
            <li *ngIf="reviewDetailsOutlet.isActivated" class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a [routerLink]="" style="cursor: pointer;" class="nav-link" (click)="onApprove()">Approve</a> <!--DEBT: Add guard here to only be shown to reviewers. Remove if already actioned -->
            </li>
            <li *ngIf="reviewDetailsOutlet.isActivated" class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#rejectModal">Reject</a> <!--DEBT: Add guard here to only be shown to reviewers. Remove if already actioned -->
            </li>
        </ul>
    </nav>
</div>


<!-- Action and reject modals & Toasts-->

<!-- DEBT: Replace with broadcast service so we dont have to keep requesting toasts-->

 <form [formGroup]="rejectForm" (ngSubmit)="onReject()">
  <div #rejectModal class="modal fade" id="rejectModal" tabindex="-1" role="dialog" aria-labelledby="rejecyModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="requestApprovalModalLabel">Request Approval</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
            <label for="rejectComment">Comment</label>
            <textarea formControlName="rejectComment" class="form-control" id="rejectComment" rows="3" placeholder="Rejection Comment"></textarea>
        </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" [disabled]="rejectForm.invalid" >Reject</button>
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
          </div>
      </div>
    </div>
  </div>
</form>

<bs-toast #actionGoodToast>
    <ng-container header>
        <strong class="me-auto text-success">Success</strong>
    </ng-container>
    <ng-container body>
        Code Review Actioned Successfully
    </ng-container>
</bs-toast>

<bs-toast #actionBadToast>
    <ng-container header>
        <strong class="me-auto text-danger">Fail</strong>
    </ng-container>
    <ng-container body>
        Failed To Action Code Review
    </ng-container>
</bs-toast>

<!-- Action and reject modals & Toasts-->



<!--Request Approval-->
 <form [formGroup]="requestApprovalForm" (ngSubmit)="onRequestApproval()">
  <div #approvalModal class="modal fade" id="requestApprovalModal" tabindex="-1" role="dialog" aria-labelledby="requestApprovalModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="requestApprovalModalLabel">Request Approval</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
            <label for="optionalRequestApprovalComment">Comment</label>
            <textarea formControlName="comment" class="form-control" id="optionalRequestApprovalComment" rows="3" placeholder="Add an optional comment to your request"></textarea>
        </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"  [disabled]="requestApprovalForm.invalid" >Request Approval</button>
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
          </div>
      </div>
    </div>
  </div>
</form>

<!-- DEBT: Replace with broadcast service so we dont have to keep requesting toasts-->

<bs-toast #approvalGoodToast>
    <ng-container header>
        <strong class="me-auto text-success">Success</strong>
    </ng-container>
    <ng-container body>
        Code Review Approval Request Successful
    </ng-container>
</bs-toast>

<bs-toast #approvalBadToast>
    <ng-container header>
        <strong class="me-auto text-danger">Fail</strong>
    </ng-container>
    <ng-container body>
        Code Review Approval Request Failed
    </ng-container>
</bs-toast>
<!--Request Code Review-->




<!--Request Code Review-->
<app-request-code-review (onSubmit)="onSubmit($event)" [packageId]="(packageInfo$ | async)?.packageId"></app-request-code-review>

<bs-toast #butteredToast>
    <ng-container header>
        <strong class="me-auto text-success">Success</strong>
    </ng-container>
    <ng-container body>
        Code Review Requested
    </ng-container>
</bs-toast>

<bs-toast #burntToast>
    <ng-container header>
        <strong class="me-auto text-danger">Fail</strong>
    </ng-container>
    <ng-container body>
        Failed to request code review
    </ng-container>
</bs-toast>
<!--Request Code Review-->
