import { Injectable } from '@angular/core';
import { SvgService } from '../../services/svg.service';
import { RelationshipType } from './relationship-type';

/**
 * Service to create a relationship graph.
 */
@Injectable({
  providedIn: 'root'
})
export class RelationshipGraphService {

  constructor(private _svgSvc: SvgService) { }

  /**
   * Create an SVG element.
   * @param width The width of the SVG element.
   * @param height The height of the SVG element.
   * @returns The SVG element.
   */
  public createSvg(width: string = '100%', height: string = '200px'): SVGElement {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', width);
    svg.setAttribute('height', height);
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    return svg;
  }

  /**
   * Add an item to the SVG.
   * @param parent The parent SVG element.
   * @param name The name of the item.
   * @param type The type of the item.
   * @param x The x-coordinate of the item.
   * @param y The y-coordinate of the item.
   * @returns The item element.
   */
  public addItem(parent: SVGElement, name: string, type: RelationshipType, x: number, y: number): SVGElement {
    var style = this.getStyle(type);

    var itemGroup = this._svgSvc.createGroup(`${x}`, `${y}`);
    itemGroup.classList.add('item');

    var iconGroup = this._svgSvc.createGroup('0', '0');
    var iconRect = this._svgSvc.createRect(style);
    var iconText = this._svgSvc.createText(type.toString(), 10, 12, 'icon');
    iconGroup.appendChild(iconRect);
    iconGroup.appendChild(iconText);
    itemGroup.appendChild(iconGroup);

    var labelText = this._svgSvc.createText(name, 30, 15);
    itemGroup.appendChild(labelText);

    var originalSize = this._svgSvc.getTextSize(name);
    var truncatedSize = this._svgSvc.getTextSize(this._svgSvc.truncate(name));
    var textSize = originalSize.width > truncatedSize.width ? truncatedSize : originalSize;

    itemGroup.setAttribute('width', `${textSize.width + 30}`);

    parent.appendChild(itemGroup);
    return itemGroup;
  }

  /**
   * Add a group to the SVG.
   * @param parent The parent SVG element.
   * @param name The name of the group.
   * @param type The type of the group.
   * @param x The x-coordinate of the group.
   * @param y The y-coordinate of the group.
   * @returns The group element.
   */
  public addGroup(parent: SVGElement, name: string, type: RelationshipType, x: number, y: number): SVGElement {
    var style = this.getStyle(type);

    var anchor = document.createElementNS('http://www.w3.org/2000/svg', 'a');

    var group = this._svgSvc.createGroup(`${x}`, `${y}`);
    group.classList.add('group');

    var rect = this._svgSvc.createRect(style);
    var text = this._svgSvc.createText(name, 10, 15);
    anchor.appendChild(rect);
    anchor.appendChild(text);
    group.appendChild(anchor);

    parent.appendChild(group);
    return group;
  }

  /**
   * Add a path to the SVG.
   * @param parent The parent SVG element.
   * @param from The starting point of the path.
   * @param to The ending point of the path.
   * @returns The path element.
   */
  public addPath(parent: SVGElement, from: { x: number, y: number }, to: { x: number, y: number }): SVGElement {
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    var xMidppint = (from.x + to.x) / 2;

    var m = `M${from.x},${from.y}`;
    var c = `C${xMidppint},${from.y} ${xMidppint},${to.y} ${to.x},${to.y}`;

    path.setAttribute('d', `${m} ${c}`);
    parent.appendChild(path);
    return path;
  }

  /**
   * Add a container to the SVG.
   * @param parent The parent SVG element.
   * @param x The x-coordinate of the container.
   * @param y The y-coordinate of the container.
   * @param width The width of the container.
   * @param height The height of the container.
   * @returns The container element.
   */
  public addContainer(parent: SVGElement, x: number, y: number, width: number, height: number): SVGElement {
    var container = this._svgSvc.createGroup(`${x}`, `${y}`);
    parent.appendChild(container);
    return container;
  }

  /**
   * Get the style for the item.
   * @param type The type of the item.
   * @returns The style of the item.
   */
  private getStyle(type: RelationshipType): string {
    var style: string;

    switch (type) {
      case RelationshipType.Automation:
        style = 'automation';
        break;
      case RelationshipType.Credential:
        style = 'credential';
        break;
      case RelationshipType.EnvironmentVariable:
        style = 'envvar';
        break;
      case RelationshipType.Object:
        style = 'object';
        break;
      case RelationshipType.Process:
        style = 'process';
        break;
      case RelationshipType.Project:
        style = 'project';
        break;
      case RelationshipType.Queue:
        style = 'queue';
        break;
      case RelationshipType.ReleasePackage:
        style = 'release';
        break;
      default:
        style = 'none';
        break;
    }

    return style;
  }
}
