import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { CreateAutomationCommand } from '../../requests/create-automation-command';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SearchResult } from '../../responses/search-response';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, debounceTime, Observer, Subject } from 'rxjs';
import { Modal } from 'bootstrap';
import { TooltipComponent } from "../../bootstrap/tooltip/tooltip.component";

@Component({
  selector: 'app-add-automation',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    TooltipComponent
  ],
  templateUrl: './add-automation.component.html',
  styleUrl: './add-automation.component.scss'
})
export class AddAutomationComponent implements AfterViewInit {

  @Input('searchResult') public searchResults$!: Subject<SearchResult[]>;
  @Input('loading') public loading: boolean = false;
  @Input('projectId') public projectId: string = '';

  @Output('onSubmit') public submitEvent = new EventEmitter<CreateAutomationCommand>();
  @Output('onSearch') public searchEvent = new EventEmitter<string>();

  @ViewChild('automationModal') automationModal!: ElementRef;


  public searchHelpText = 'Enter a search term to find the <span class="fw-bold text-release">Project</span>.';
  public projectHelpText = 'Select the <span class="fw-bold text-release">Project</span> for which you wnant to create an <span class="fw-bold text-automation">Automation Fact Sheet</span>.';
  public detailsHelpText = 'Please enter a Name and Description for your <span class="fw-bold text-automation">Automation Fact Sheet</span>.';
  public createAutomationHelpText = 'Please create a new <span class="fw-bold text-automation">Automation Fact Sheet</span> to start using IA DevOps for your development.';
  public faCircleQuestion = faCircleQuestion;
  public form: FormGroup = new FormGroup({});
  public noContentResult: boolean = false;
  public searchResultSpinner: string = 'searchResultSpinner';

  get search() { return this.form.get('search') as FormControl; }
  get id() { return this.form.get('projectId') as FormControl; }
  get name() { return this.form.get('name') as FormControl; }
  get description() { return this.form.get('description') as FormControl; }

  private _modal!: Modal;

  constructor(private _spinnerSvc: NgxSpinnerService) {
    this.form = new FormGroup({
      search: new FormControl(''),
      projectId: new FormControl({ value: '', disabled: true }, [Validators.required]),
      name: new FormControl({ value: '', disabled: true }, [Validators.required]),
      description: new FormControl({ value: '', disabled: true })
    });
  }

  public ngAfterViewInit(): void {
    this.initForm();
    this.initModal();
  }

  /**
   * Raise submit event
   */
  public onSubmit(): void {
    if (this.form.invalid) return;

    var request = {
      projectId: this.id.value,
      name: this.name.value,
      description: this.description.value
    } as CreateAutomationCommand;

    this.submitEvent.emit(request);
  }

  /**
   * Show the modal
   */
  public show(): void {
    this._modal.show();
  }

  /**
   * Hide the modal
   */
  public hide(): void {
    this._modal.hide();
  }

  /**
   * Initialize the form
   */
  private initForm(): void {
    this.id?.valueChanges
      .subscribe(this.projectValueChanges());


    if (this.projectId !== '') {

      setTimeout(() => {
        this.id.setValue(this.projectId)
        this.name.enable();
        this.description.enable();
      }, 0);
      return;
    }

    this.search?.valueChanges
      .pipe(debounceTime(500))
      .subscribe(this.searchValueChanges());


    this.searchResults$.subscribe((results: SearchResult[]) => {
      this.noContentResult = results.length === 0;
      results.length > 0 ? this.id.enable() : this.id.disable();
    });

    console.log(this.projectId);

  }

  /**
   * Initialize the modal
   */
  private initModal(): void {
    this._modal = new Modal(this.automationModal.nativeElement);

    this.automationModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.form.reset();
      this.id?.disable();
      this.name?.disable();
      this.description?.disable();
      var backdrops = document.getElementsByClassName('modal-backdrop');
      for (var i = 0; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    });
  }

  /**
   * Triggered when the value of the search form control changes
   * Searches for Blue Prism releases based on the search term
   */
  private searchValueChanges(): Partial<Observer<any>> {
    return {
      next: (value) => {
        this.searchEvent.emit(value);
      }
    }
  }

  /**
   * Triggered when the value of the projectId form control changes
   * Enables the name and description form controls
   */
  private projectValueChanges(): Partial<Observer<any>> {
    return {
      next: value => {
        if (value === null || value === '') return;
        this.name.enable();
        this.description.enable();
      }
    }
  }
}
