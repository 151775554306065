/**
 * Used to indicate new versioning on a code review request
 */
export enum ChangeTypeEnum {
  Major = 1,
  Minor = 2,
  Patch = 3,
  Build = 4
}

/**
 * Allows mapping to display the name of the enum properly
 */
export const ChangeTypeMapping = {
  [ChangeTypeEnum.Major]: 'New or Re-Development',
  [ChangeTypeEnum.Minor]: 'New Functionality or Enhancement',
  [ChangeTypeEnum.Patch]: 'Bug Fixes',
  [ChangeTypeEnum.Build]: 'Re-Deployment'
};
