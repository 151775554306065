import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { PageHeaderComponent } from "../../components/page-header/page-header.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'bootstrap';
import { NgFor, NgIf } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from '../../services/search.service';
import { SearchRequest } from '../../requests/search-request';
import { SearchResult } from '../../responses/search-response';
import { AutomationService } from '../../services/automation.service';
import { CreateAutomationCommand } from '../../requests/create-automation-command';
import { AddAutomationComponent } from "../../components/add-automation/add-automation.component";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    PageHeaderComponent,
    FontAwesomeModule,
    NgxSpinnerModule,
    AddAutomationComponent
  ],
  templateUrl: './dashboard.page.html',
  styleUrl: './dashboard.page.scss'
})
export class DashboardPage implements AfterViewInit {

  @ViewChild(AddAutomationComponent) addAutomationComponent!: AddAutomationComponent;

  @ViewChild('createAutomationHelp') createAutomationHelp!: ElementRef;
  public createAutomationHelpText = 'Please create a new <span class="fw-bold text-automation">Automation Fact Sheet</span> to start using IA DevOps for your development.';

  public faCircleQuestion = faCircleQuestion;
  public searchResultSpinner: string = 'searchResultSpinner';
  public searchResults$: Subject<SearchResult[]> = new Subject<SearchResult[]>();

  constructor(
    private _searchSvc: SearchService,
    private _spinnerSvc: NgxSpinnerService,
    private _automationSvc: AutomationService
  ) {
  }

  public ngAfterViewInit(): void {
    this.initTooltips();
  }

  public onSubmit(request: CreateAutomationCommand): void {
    if (request.projectId === null || request.projectId === '' || request.name === null || request.name === '') return;

    this._automationSvc.createAutomation(request).subscribe({
      next: response => {
        this.addAutomationComponent.hide();
      }
    });
  }

  private initTooltips(): void {
    var createAutomationTooltip = Tooltip.getOrCreateInstance(this.createAutomationHelp.nativeElement);
    createAutomationTooltip?.setContent({ '.tooltip-inner': this.createAutomationHelpText });
  }

  public onSearch(value: string): void {
    var request = { phrase: value } as SearchRequest;
    this._searchSvc.searchProjects(request).subscribe({
      next: response => {
        this.searchResults$.next(response.results);
      }
    });
  }
}
