export const environment = {
    production: false,
    bluePrismUrl: 'https://iadoeus2np01dev01app06.tjxcloud.com',
    projectUrl: 'https://iadoeus2np01dev01app02.tjxcloud.com',
    devOpsUrl: 'https://iadoeus2np01dev01app03.tjxcloud.com',
    appInsights: {
        connectionString: 'InstrumentationKey=1de3a185-d5ad-4df6-b601-011d83dfa7d0;IngestionEndpoint=https://eastus2-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=d9a319b6-6346-471d-9081-97ffc421a447',
        enableAutoRouteTracking: true
    },
    logProvider: 'insights'
};
