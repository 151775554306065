import { Component, OnInit, ViewChild } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RelationshipViewComponent } from '../../components/relationship-view/relationship-view.component';
import { AutomationNavigationComponent } from '../../components/automation-navigation/automation-navigation.component';
import { Observer, of, Subject, switchMap } from 'rxjs';
import { GetReviewResponse, ReviewFinding } from '../../responses/get-review-response';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule, AbstractControl, FormControl } from '@angular/forms';
import { CommonModule, NgIf } from '@angular/common';
import { ChildParentSharedService } from '../../services/child-parent-shared.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateReviewerFindingCommand, ReviewerFinding } from '../../requests/create-reviewer-finding-command';
import { CreateReviewerFindingResponse } from '../../responses/create-reviewer-finding-response';
import { ToastComponent } from "../../bootstrap/toast/toast.component";
import { DevOpsService } from '../../services/devops.service';

@Component({
  selector: 'app-automation-review-details',
  standalone: true,
  imports: [
    PageHeaderComponent,
    RelationshipViewComponent,
    AutomationNavigationComponent,
    CommonModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgIf,
    ToastComponent
],
  templateUrl: './automation-review-details.page.html',
  styleUrl: './automation-review-details.page.scss'
})
export class AutomationReviewDetailsPage implements OnInit {
  public spinnerName: string = 'review-spinner';
  public review$: Subject<GetReviewResponse> = new Subject<GetReviewResponse>();
  public processNames: string[] = [];
  public errorMessage: string | null = null;
  public form!: FormGroup;
  public overallAutomationComments!: FormGroup[]
  public findingForm!: FormGroup;
  public addFindingOn = false;
  public successToastMessage!: string;
  public errorToastMessage! : string ;
  private _reviewId!: string;

  get findings() {return this.form.get('findings') as FormArray; }
  get page() {return this.findingForm.get('page') as FormControl; }
  get stage() {return this.findingForm.get('stage') as FormControl; }
  get severity() {return this.findingForm.get('severity') as FormControl; }
  get message() {return this.findingForm.get('message') as FormControl;}

  @ViewChild('successToast') successToast!: ToastComponent;
  @ViewChild('errorToast') errorToast!: ToastComponent;

  constructor(
    private _spinnerSvc: NgxSpinnerService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _childParentSharedService: ChildParentSharedService,
    private _devopsService: DevOpsService
  ) {
    this.getReviewId();
    this.initForms();
  }

  /**
   * When component is initialized: shows spinner, fetches review ID from route, gets RoboReview, and subscribes to observer.
   */
  ngOnInit(): void {
    this._spinnerSvc.show(this.spinnerName);
    this._route.paramMap
      .pipe(switchMap((params) => { return of(params.get('reviewId') as string); }))
      .pipe(switchMap((reviewId) => { return this._devopsService.getReview({ reviewId }); }))
      .subscribe(this.getRoboReviewObserver());
  }


  /**
   * Initializes all forms for this page
   */
  private initForms(){
    this.form = this._fb.group({
      findings: this._fb.array([])
    })

    this.findingForm = this._fb.group({
      page: [null, Validators.required],
      stage: [null, Validators.required],
      severity: [null, Validators.required],
      message: [null, Validators.required]
    })
  }

  /**
   * Gets review id from the url, and adds it as a subject for parent components to subscribe to
   */
  private getReviewId() {
    // Get the current automation id from the route
    this._route.paramMap
      .pipe(
        switchMap((params) => {
          return of(params.get('reviewId') as string);
        })
      )
      .subscribe((reviewId) => {
        this._reviewId = reviewId;
        this._childParentSharedService.reviewIdSubject$.next(this._reviewId) // Push id to parent
      });
  }

  /**
   * Observer for handling RoboReview responses. Updates observable, hides spinner,
   * processes findings into form groups, handles errors, and logs completion.
   */
  private getRoboReviewObserver(): Observer<GetReviewResponse> {
    return {
      next: (review) => {
        this.review$.next(review);
        this._spinnerSvc.hide(this.spinnerName);
        this.errorMessage = null;
        this.processNames = this.getProcessNames(review);
        this.populateForm(review.reviewFindings);
        this.overallAutomationComments = this.getGroupsByProcess(null); // Pass in null for process names as overall automation comments wont have a process name
      },
      error: (err) => {
        console.error(err);
        this._spinnerSvc.hide(this.spinnerName);
        this.errorMessage = 'An error occurred while fetching the review.';
      },
      complete: () => {  }
    } as Observer<GetReviewResponse>;
  }

  /**
   * Populates the form with review findings.
   * @param reviewFindings - An array of ReviewFinding objects to populate the form.
   */
  populateForm(reviewFindings: ReviewFinding[]) {
    const items = this.form.get('findings') as FormArray;
    reviewFindings.forEach(finding => {
      items.push(this._fb.group({
        bpaProcessName: [finding.bpaProcessName],
        bpStageName: [finding.bpStageName],
        bpSubsheetName: [finding.bpSubsheetName],
        severity: [finding.severity],
        error: [finding.error]
      }));
    });
  }

  /**
   * Adds a new finding to the form.
   * @param bpaProcessName - The name of the BP process.
   * @param bpStageName - The name of the stage in the BP process.
   * @param bpSubsheetName - The name of the subsheet in the BP process.
   * @param message - Description of the error found.
   */
  addProcessFinding(process: string | null, page: string, stage: string, severity: string, message: string) : FormGroup {
    return this._fb.group({
      bpaProcessName: [process],
      bpStageName: [page],
      bpSubsheetName: [stage],
      severity: [severity],
      error: [message],
    });
  }

  /**
   * Retrieves form groups by BPA process name.
   * @param id - The BPA process name to group by.
   * @returns An array of FormGroup objects that match the given BPA process name.
   */
  getGroupsByProcess(id: string | null) : FormGroup[] {
    const items = this.form.get('findings') as FormArray;
    const groups = items.controls
      .filter((control: AbstractControl) => (control as FormGroup).get('bpaProcessName')!.value === id)
      .map(control => control as FormGroup);
    return groups;
  }

  /**
   * Retrieves unique BPA process names from the review findings.
   * @param review - The GetReviewResponse object containing review findings.
   * @returns An array of unique BPA process names.
   */
  getProcessNames(review: GetReviewResponse): string[] {
    const processNames = review.reviewFindings.map(finding => finding.bpaProcessName).filter(name => name !== "" && name !== null);
    return Array.from(new Set(processNames));
  }

  /**
   * Retrieves the score associated with a given BPA process name.
   * @param review - The GetReviewResponse object containing review scores.
   * @param process - The BPA process name to find the score for.
   * @returns The score as a string if found, otherwise undefined.
   */
  getScore(review: GetReviewResponse, process: string): string | undefined {
    const score = review.reviewScores.find(score => score.bpaProcessName === process);
    return score ? score.score : undefined;
  }

  /**
   * Calculates the average score of all review scores in the given review.
   * @param review - The GetReviewResponse object containing review scores.
   * @returns The average score as a string if there are scores, otherwise undefined.
   */
  getCompositeScore(review: GetReviewResponse): string | undefined {
    const scores = review.reviewScores.map(score => parseInt(score.score, 10));
    if (scores.length === 0) {
      return undefined;
    }
    const total = scores.reduce((sum, score) => sum + score, 0);
    const average = total / scores.length;
    return average.toFixed(0);
  }

  /**
   * Determines the score type based on the score value.
   * @param scoreStr - The score as a string.
   * @returns 'danger' if the score is between 0 and 69, 'warning' if the score is between 70 and 84, 'success' if the score is between 85 and 100, otherwise undefined.
   */
  getScoreType(scoreStr: string | undefined): string | undefined {
    const score = scoreStr ? parseInt(scoreStr, 10) : undefined;
    if (score !== undefined) {
      if (score >= 0 && score < 70) {
        return 'danger';
      } else if (score >= 70 && score < 85) {
        return 'warning';
      } else if (score >= 85 && score <= 100) {
        return 'success';
      }
    }
    return undefined;
  }

  /**
   * Determines if a collapsable html element is exapnded or not
   * @param target accordion to check if it is open or not
   * @returns a boolean indicating if the accordion is expanded or not
   */
  public isCollapsed(target: HTMLElement) : boolean{
    return target.getAttribute('aria-expanded') === "true";
  }

  /**
   * Indicates that a finding is being created
   * @param event Event that indicates the add finidng button was clicked
   */
  public onAddFinding(event: Event): void{
    event.stopPropagation(); // Stop the add finding button activating the click event of the parent div
    this.addFindingOn = true;
  }

  /**
   * Listens for when an accordion is collapsed and resets the findings form so it is clear for the newly opened accordion
   */
  public onCollapseEvent(){
    if (this.addFindingOn === true){
          this.addFindingOn = false;
          this.findingForm.reset();
    }
  }

  /**
   * Submits finding data to the server
   * @param headerName Which accordion folder it belongs too
   */
  public onSubmitFinding(headerName: string | null){
    let request = {
      reviewId: this._reviewId,
      findings: [
        {
          findingType: this.severity.value,
          reviewerComment: this.message.value,
          pageName: this.page.value,
          stageName: this.stage.value,
          processName: headerName
        } as ReviewerFinding,
      ],
    } as CreateReviewerFindingCommand;

    this._devopsService.addFinding(request).subscribe(this.getAddFindingObserver(headerName))
  }

  /**
   *  Gets the observable from sending the add finding data to the server
   * @param headerName Which accordion it belongs too
   * @returns an Observable of the Http response
   */
  private getAddFindingObserver(headerName: string | null): Observer<CreateReviewerFindingResponse>{
    return {
      next: (response) => {
          // Create form group with the given data
          var formGroup = this.addProcessFinding(headerName, this.page.value, this.stage.value, this.severity.value, this.message.value );

          // If there is no page name to the input, then that means it was an overall automation comment
          if(!this.page.value){
            this.overallAutomationComments.splice(0, 0, formGroup); // add to top of array
          }else{
            const items = this.form.get('findings') as FormArray;
            items.insert(0, formGroup); // add to top of array
          }

          // Reset the form and hide the adding fields
          this.findingForm.reset()
          this.addFindingOn = false

          // Show toast for succesfully showing message
          this.successToastMessage = "Finding Added Successfully";
          this.successToast.showToast();
      },
      error:(err) => {
        console.error(err);
        this.errorToastMessage = "Failed To Add Finding";
        this.errorToast.showToast();
      },
    complete: () => {  }
    } as Observer<CreateReviewerFindingResponse>;
  }
}


