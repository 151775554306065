import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  viewChild,
  ViewChild,
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { RelationshipViewComponent } from '../../components/relationship-view/relationship-view.component';
import { AutomationNavigationComponent } from '../../components/automation-navigation/automation-navigation.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestCodeReviewComponent } from '../../components/request-code-review/request-code-review.component';
import { RequestCodeReviewCommand } from '../../requests/request-code-review-command';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { filter, Observer, of, Subject, Subscription, switchMap } from 'rxjs';
import { GetAutomationGeneralInformationResponse } from '../../responses/get-automation-general-information-response';
import { AsyncPipe, CommonModule, Location} from '@angular/common';
import { AutomationService } from '../../services/automation.service';
import { DevOpsService } from '../../services/devops.service';
import { ToastComponent } from '../../bootstrap/toast/toast.component';
import { GetAutomationPackageResponse } from '../../responses/get-automation-package-response';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ComponentIconComponent } from '../../components/component-icon/component-icon.component';
import { ChildParentSharedService } from '../../services/child-parent-shared.service';
import { RequestApprovalCommand } from '../../requests/request-approval-command';
import { ActionReviewCommand } from '../../requests/action-review-command';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-automation-code-review',
  standalone: true,
  imports: [
    PageHeaderComponent,
    AsyncPipe,
    RelationshipViewComponent,
    AutomationNavigationComponent,
    FontAwesomeModule,
    RequestCodeReviewComponent,
    ToastComponent,
    RouterOutlet,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ComponentIconComponent,
  ],
  templateUrl: './automation-code-review.page.html',
  styleUrl: './automation-code-review.page.scss',
})

/**
 * Page that displays all code reviews for a gicen automation
 */
export class AutomationCodeReviewPage implements OnInit, AfterViewInit {
  public packageInfo$: Subject<GetAutomationPackageResponse> = new Subject();
  public requestApprovalForm: FormGroup = new FormGroup({});
  public rejectForm: FormGroup = new FormGroup({});
  get rejectComment() {
    return this.rejectForm.get('rejectComment') as FormControl;
  }
  get comment() {
    return this.requestApprovalForm.get('comment') as FormControl;
  }
  public generalInfo$: Subject<GetAutomationGeneralInformationResponse> =
    new Subject();
  private _automationId!: string;
  private _getReviewIdSub = new Subscription();
  private _reviewId!: string | null;

  @ViewChild('approvalModal') requestApprovalModal!: ElementRef;
  @ViewChild('rejectModal') rejectReviewModal!: ElementRef;
  @ViewChild(RequestCodeReviewComponent)
  requestCodeReviewComponent!: RequestCodeReviewComponent;
  @ViewChild('butteredToast') goodToast!: ToastComponent;
  @ViewChild('burntToast') badToast!: ToastComponent;
  @ViewChild('approvalGoodToast') goodApprovalToast!: ToastComponent;
  @ViewChild('approvalBadToast') badApprovalToast!: ToastComponent;
  @ViewChild('actionGoodToast') actionGoodToast!: ToastComponent;
  @ViewChild('actionBadToast') actionBadToast!: ToastComponent;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _automationSvc: AutomationService,
    private _devopsService: DevOpsService,
    private _childParentSharedService: ChildParentSharedService
  ) {
    this.getAutomationId();
    this.subscribeToChildComponents();
    this.initForms();
  }

  /**
   * Initializes all forms used on the page
   */
  private initForms(): void {
    this.requestApprovalForm = new FormGroup({
      comment: new FormControl(null),
    });

    this.rejectForm = new FormGroup({
      rejectComment: new FormControl(null, [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    this.initModals();
  }

  ngOnInit(): void {
    // Get package info at startup
    this._automationSvc
      .getPackage({ automationId: this._automationId })
      .subscribe(this.getPackageObserver());
    this._automationSvc
      .getGeneralInformation({ automationId: this._automationId })
      .subscribe(this.getGeneralInformationObserver());
  }

  /**
   * Gets automation id from the route
   */
  private getAutomationId() {
    // Get the current automation id from the route
    this._route.paramMap
      .pipe(
        switchMap((params) => {
          return of(params.get('id') as string);
        })
      )
      .subscribe((automationId) => {
        this._automationId = automationId;
      });
  }

  /**
   * Resets the form within the modal whenever the modal is dismissed
   */
  private initModals() {
    this.requestApprovalModal.nativeElement.addEventListener(
      'hidden.bs.modal',
      () => {
        this.requestApprovalForm.reset();
      }
    );
    this.rejectReviewModal.nativeElement.addEventListener(
      'hidden.bs.modal',
      () => {
        this.rejectForm.reset();
      }
    );
  }

  /**
   * Handle the response from the get general information request
   */
  private getGeneralInformationObserver(): Partial<Observer<any>> {
    return {
      next: (generalInfo) => {
        this.generalInfo$.next(generalInfo);
      }
    } as Observer<GetAutomationGeneralInformationResponse>;
  }

  /**
   * Handle the response from the get package request
   */
  private getPackageObserver(): Partial<Observer<any>> {
    return {
      next: (packageInfo) => {
        this.packageInfo$.next(packageInfo);
        // DEBT: Have to consider if we get no content here (disable request review, show warning no packages yet, etc)
      },
    } as Observer<GetAutomationPackageResponse>;
  }

  /**
   * Submits a request to request a new code review
   * @param request Package and change type of requested automations code review
   */
  public onSubmit(request: RequestCodeReviewCommand): void {
    if (request == null || request.packageId === null || request.change === null) {
      this.badToast.showToast(); // DEBT: Move to broadcast service and add specified information that no package id was present
      return;
    }

    this._devopsService.requestReview(request).subscribe({
      next: (response) => {
        this.goodToast.showToast(); // Show success message
      },
      error: (e) => {
        this.badToast.showToast(); // Show fail message
      },
    });
  }

  /**
   * Initiates connection to child components via shared services
   */
  public subscribeToChildComponents() {
    // Get review Id subscription
    this._getReviewIdSub =
      this._childParentSharedService.reviewIdSubject$.subscribe((reviewId) => {
        this._reviewId = reviewId;
      });
  }

  /**
   * Stops connection to child component when the router outlet is closed
   */
  public onChildComponentDeactivate(event: any) {
    this._getReviewIdSub.unsubscribe();
    this._reviewId = null;
  }

  /**
   * Sends http request to label a review as ready to be reviewed
   */
  public onRequestApproval(): void {
    let request = {
      reviewId: this._reviewId,
      developerComment: this.comment.value,
    } as RequestApprovalCommand;

    // Toast display
    this._devopsService.requestApproval(request).subscribe({
      next: (response) => {
        // DEBT: Replace all these toasts with broadcast service to avoid replication of code
        this.goodApprovalToast.showToast(); // Show success message
      },
      error: (e) => {
        this.badApprovalToast.showToast(); // Show fail message
      },
    });
  }

  /**
   * Sets the request for actioning a code review to approve then calls the method to action it
   */
  public onApprove(): void {
    let request = {
      ReviewId: this._reviewId,
      Rejected: false,
    } as ActionReviewCommand;

    this.actionReview(request);
  }

  /**
   * Sets the request for actioning a code review to reject  then calls the method to action it
   */
  public onReject(): void {
    let request = {
      ReviewId: this._reviewId,
      Rejected: true,
      RejectionReason: this.rejectComment.value,
    } as ActionReviewCommand;

    this.actionReview(request);
  }

  /**
   * Actions a code review then displays the proper popup
   * @param request Data that indicates if the review is approved or rejected
   */
  private actionReview(request: ActionReviewCommand) {
    this._devopsService.action(request).subscribe({
      next: (response) => {
        Modal.getOrCreateInstance(this.rejectReviewModal.nativeElement).hide(); // Manually hide modal only if its a success
        var backdrops = document.getElementsByClassName('modal-backdrop'); // Remove all modal backdrops to dismiss modal
        for (var i = 0; i < backdrops.length; i++) {
          backdrops[i].remove();
        }
        this._router.navigate([`./`], { relativeTo: this._route }).then(() => {
          this.actionGoodToast.showToast();
        });
      },
      error: (err) => {
        this.actionBadToast.showToast();
      },
    });
  }
}
