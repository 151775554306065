import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';


@Component({
  selector: 'bs-toast',
  standalone: true,
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent implements AfterViewInit {
  @ViewChild('toast') toast!: ElementRef;
  @ViewChild('toastBtn') toastBtn!: ElementRef;

  private _toast!: Toast;

  constructor() { }

  public ngAfterViewInit(): void {
    this._toast = Toast.getOrCreateInstance(this.toast.nativeElement,);
  }

  /**
   * Show the toast.
   */
  public showToast(): void {
    this._toast.show();
  }
}
