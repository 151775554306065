import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestCodeReviewCommand } from '../requests/request-code-review-command';
import { Observable } from 'rxjs';
import { Obj } from '@popperjs/core';
import { RequestCodeReviewResponse } from '../responses/request-code-review-response';
import { CreateReviewerFindingCommand } from '../requests/create-reviewer-finding-command';
import { CreateReviewerFindingResponse } from '../responses/create-reviewer-finding-response';
import { RequestApprovalCommand } from '../requests/request-approval-command';
import { ActionReviewCommand } from '../requests/action-review-command';
import { GetReviewQuery } from '../requests/get-review-query';
import { ActionReviewResponse } from '../responses/action-review-response';
import { GetReviewResponse } from '../responses/get-review-response';

@Injectable({
  providedIn: 'root',
})

/**
 * Service to make all requests to devops microservice
 */
export class DevOpsService {
  private _devopsUrl: string;

  constructor(private _client: HttpClient) {
    this._devopsUrl = environment.devOpsUrl;
  }

  /**
   * Initiates a review for a given package
   * @param request Package to be requested and its desired change
   * @returns Session Id of automatic review
   */
  public requestReview(request: RequestCodeReviewCommand): Observable<RequestCodeReviewResponse>{
      return this._client.post<RequestCodeReviewResponse>(`${this._devopsUrl}/code-review/request`, request)
  }

  /**
   * Adds a finding to a review
   * @param request Findings data
   * @returns Review and new created finding ids
   */
  public addFinding(request: CreateReviewerFindingCommand): Observable<CreateReviewerFindingResponse>{
      return this._client.post<CreateReviewerFindingResponse>(`${this._devopsUrl}/code-review/findings`, request)
  }

    public getReview(request: GetReviewQuery): Observable<GetReviewResponse> {
    return this._client.get<GetReviewResponse>(`${this._devopsUrl}/code-review/${request.reviewId}`);
  }

  /**
   * Requests approval on a desired review
   * @param request Review data to update
   * @returns Observable
   */
  public requestApproval(request: RequestApprovalCommand): Observable<any>{
    return this._client.put(`${this._devopsUrl}/code-review/request-approval`, request);
  }

  /**
   * Actions a code review stating approved or rejected
   * @param request Data indicating if the code has been approved or rejected
   * @returns Id of review actioned
   */
  public action(request:ActionReviewCommand): Observable<ActionReviewResponse>{
    return this._client.put<ActionReviewResponse>(`${this._devopsUrl}/code-review/action`, request);
  }
}
