import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';
import { KeyValuePipe, NgFor } from '@angular/common';
import { ChangeTypeMapping, ChangeTypeEnum } from '../../enums/changeTypes.enums';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RequestCodeReviewCommand } from '../../requests/request-code-review-command';

@Component({
  selector: 'app-request-code-review',
  standalone: true,
  imports: [KeyValuePipe, NgFor, ReactiveFormsModule],
  templateUrl: './request-code-review.component.html',
  styleUrl: './request-code-review.component.scss',
})

/**
 * Creates a Modal for requesting a code review
 */
export class RequestCodeReviewComponent implements AfterViewInit {
  @ViewChild('requestModal') requestCodeReviewModal!: ElementRef;
  @Output('onSubmit') public submitEvent = new EventEmitter<RequestCodeReviewCommand>();
  @Input('packageId') public packageId!: string | undefined;

  public changeTypeMapping = ChangeTypeMapping;
  public changeTypes = ChangeTypeEnum;
  public form: FormGroup = new FormGroup({});

  get change() { return this.form.get('change') as FormControl;}

  constructor() {
    this.form = new FormGroup({change: new FormControl(undefined, Validators.required), 
    });
  }

  public ngAfterViewInit(): void {
    this.initModal();
  }

  /**
   * Resets the form within the modal whenever the modal is dismissed
   */
  private initModal() {
    this.requestCodeReviewModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.form.reset();
    });
  }


  /**
   * Emits a submit event for a review to be requested and creates a request code review request
   */
  public onSubmit(): void {
    var request = {
      packageId: this.packageId,
      change: +ChangeTypeEnum[this.change.value],
    } as RequestCodeReviewCommand;
     console.log(request);
    this.submitEvent.emit(request);
  }
}
