import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Tooltip } from 'bootstrap';

@Component({
  selector: 'bs-tooltip',
  standalone: true,
  imports: [],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent implements AfterViewInit {
  @Input('content') content: string = '';

  @ViewChild('tooltip') tooltipRef!: ElementRef;

  ngAfterViewInit(): void {
    var addItemTooltip = Tooltip.getOrCreateInstance(this.tooltipRef.nativeElement);
    addItemTooltip?.setContent({ '.tooltip-inner': this.content });
  }
}
