<div class="page-grid">
  <header>
    <app-page-header [hasShadow]="false">
      <div class="d-flex gap-3">
        <app-component-icon type="Project" size="l" />
        <span>{{(generalInfo$ | async)?.name}}</span>
      </div>
    </app-page-header>
  </header>
  <nav></nav>
  <main>
    <app-general-information>
      <div name>{{(generalInfo$ | async)?.name}}</div>
      <div description>{{(generalInfo$ | async)?.description}}</div>
      <div blue-prism-id>{{(generalInfo$ | async)?.id}}</div>
    </app-general-information>
  </main>
  <nav class="page-actions">
    <ul class="navbar-nav">
      <li class="nav-title">Actions</li>
      <li class="nav-item d-flex flex-row flex-grow-1 justify-content-between">
        <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#createAutomationModal">Create Automation</a>
        <a #createAutomationHelp href="#" class="nav-item-icon" data-bs-toggle="tooltip" data-bs-title="placeholder" data-bs-html="true">
          <fa-icon [icon]="faCircleQuestion"></fa-icon>
        </a>
      </li>
    </ul>
  </nav>
</div>

<app-add-automation [projectId]="projectId" (onSubmit)="onSubmit($event)" />